import * as React from "react"

const CircleWithFlowers = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="55 23 1305 1305"
    xmlSpace="preserve"
    height="100%"
    width="100%"
    x={0}
    y={0}
    {...props}
  >
    <style>
      {
        ".st0,.st1{stroke:rgba(77, 77, 77, 0.5)}.st0{fill:none;stroke-width:5px}.st1{fill:#231f20;stroke-miterlimit:10;stroke-width:2}"
      }
    </style>
    <g id="layer1" transform="translate(0 232.167)">
      <path
        id="path812-7"
        className="st0"
        d="M1355.5 443.6c0 357.9-290.1 648-648 648s-648-290.1-648-648 290.1-648 648-648 648 290.1 648 648z"
      />
      <g id="g898" transform="translate(-819.462 28.344)">
        <path id="path815-9" className="st0" d="M1065.1-39.2h923.8" />
        <path id="path815-4-0" className="st0" d="M920.2 188h1213.7" />
        <path id="path815-0-6" className="st0" d="M879 415.3h1296" />
        <path id="path815-47-5" className="st0" d="M920.2 642.5h1213.7" />
        <path id="path815-07-2" className="st0" d="M1065.1 869.8h923.8" />
      </g>
    </g>
    <path
      className="st1"
      d="M517.9 999.7c1.6-30.6-14.5-57.6-38.3-68.8.8-38.1-25.6-69.9-59.8-71.7-24.1-1.2-45.8 12.7-57.6 34.2-10.8-13.3-26.2-22-43.6-22.9-35-1.8-65 28.5-67 67.6-.9 18.3 4.4 35.3 13.9 48.4-13.9 12.3-23.3 30.9-24.4 52.2-2 39.2 24.7 72.4 59.8 74.2 12.5.6 24.3-2.8 34.4-9.2 8.7 25 29.9 43.2 55.6 44.5 35 1.8 65-28.5 67-67.6.2-4.5.1-8.9-.4-13.3 32-2 58.5-30.9 60.4-67.6zm-164-2.8c5.9-5.2 11-11.6 15-18.8 6.1 7.5 13.6 13.5 22.1 17.5-.1 3.7.1 7.3.5 10.8-10.1.6-19.5 3.9-27.9 9.2-2.4-6.8-5.6-13.1-9.7-18.7z"
    />
    <path
      className="st1"
      d="M697.2 1115.8c14.5-35 1.6-74.9-29.5-89.9-21.8-10.6-46.9-6.1-65.7 9.2-5.2-16.6-16.3-30.6-32.1-38.3-31.7-15.4-70.5 1-86.5 36.5-7.5 16.6-8.7 34.5-4.6 50.3-17.3 6-32.7 19.6-41.4 38.9-16 35.6-3.2 76.8 28.5 92.2 11.3 5.5 23.5 6.9 35.2 4.9-1 26.6 12.1 51.8 35.3 63.1 31.7 15.4 70.5-1 86.5-36.5 1.8-4.1 3.3-8.3 4.4-12.5 30.5 10.7 65.4-5.7 80.4-39.1 12.6-27.8 7.5-59.2-10.5-78.8zm-137.9 33.3c.3-7.3-.5-14.4-2.2-21.2 7.3-2.5 14.4-6.4 20.6-11.6 2.9 9.3 7.7 17.9 14.2 24.9-1.4 3.4-2.5 6.8-3.4 10.2-9.6-3.3-19.5-4-29.2-2.3zM546.6 922.7c-8.9 16.6-32.5 25.3-50.8 20.7-28.7-7.2-39.5-45.8-44.7-64.5-3.3-11.9-.5-8.2-7.4-47.2-4.3-24.3-6.6-32.3-2.4-35.7 6.6-5.5 23.8 5.5 41.1 16.5 8.1 5.2 16.8 10.7 25.4 18.8 17.5 16.4 54.7 61.6 38.8 91.4z"
    />
    <path
      className="st1"
      d="M710.5 1242.6c-22.8-22.1-25.4-70.4-.3-91.2 28.7-23.8 79 .9 94.1 27.2 1.7 3 .3 1.4 11.4 29.2 10.9 27.4 14.1 33.5 11 38.5-3.4 5.4-10.3 3.1-33.7 6.7-17.4 2.7-18 4.6-27 5.5-3 .4-35.7 3.4-55.5-15.9zM320.3 813.1c-1.3-20.5-15.7-22.7-33.6-63.4-15.1-34.2-14.3-54.1-22.7-54.7-12.6-.9-25.2 38.6-32.1 59.8-6.6 20.6-10.1 33.1-11 50.1-.1 1-.1 2.1-.1 3.2l-9.9-10.1c1.8-14.1-.5-31.6-7.3-49.2-6.3-16.2-15.4-26.3-32.1-43.9-15.1-15.9-33.1-35-41.9-30.2-6.6 3.5-4.9 21.2-1.2 56.3 2.6 25 4.1 37.4 7.6 46.5 13.5 34.6 39.4 56.3 58.1 48.6l37.9 38.7c5.3 11.2 12.6 19.8 22.1 22.2 27.3 6.8 68.5-37.9 66.2-73.9zM204 899.1c-23.2-2.9-38.5 2.4-44 4.5-.3.1-.4.2-1 .4-40.2 15.9-65.3 18.2-66.1 26.1-.9 8.3 26.7 8 47.3 34.4 8.4 10.8 8.6 17 18 24.1 13.7 10.3 29.9 9.7 38.4 9.3 36.5-1.8 86.5-27 85.1-53.4-1.4-27.8-59.7-43.2-77.7-45.4z"
    />
    <path
      className="st1"
      d="M934.5 1104.7c-10.9-3.8-35.9-12.6-62.9.2-9.5 4.5-28.7 16.2-35.4 30.7l-14.5-.7c18.4-19.8 27-43.7 27.8-46.1 12-35 6.9-76.4-4.7-79.6-7.7-2.1-11.3 14.4-33.2 27.7-19.4 11.8-27.4 5.4-60.9 16.5-24.2 8-39.1 13-47.4 27.9-8.4 15.1-6.6 34.4-.1 47.9 13.1 27.2 47.1 34.2 69.3 31.9 4-.4 7.8-1.2 11.5-2.2l-.2 3.2 60.2 3.1c13.3 11.7 37.1 17.8 55.6 13.4 4.4-1 20.4-4.8 25.6-17.1 3.5-8.2-1.2-12.4 2.2-20.7 6.1-14.9 26.5-15 26.8-22.6.2-6.5-15.2-11.9-19.7-13.5z"
    />
  </svg>
)

export default CircleWithFlowers
