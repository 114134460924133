export const fontRegistry = [
  // popular picks script
  {
    fontFamily: 'MadinaClean',
    displayName: 'Sally',
    script: true,
    popularPick: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Dmitrii Chirkov-Joshico-Regular',
    displayName: 'Mandy',
    script: true,
    popularPick: true,
    size: 3.5,
    favorite: false,
    styles: {
      fontVariantLigatures: 'none'
    }
  },
  {
    fontFamily: 'ZaneStudio-HelloSunriseScript',
    displayName: 'Abigail',
    script: true,
    popularPick: true,
    size: 5,
    favorite: false,
  },
  // popular picks block
  {
    fontFamily: 'KimberlyGeswein-KGRiseUP',
    displayName: 'Robert',
    script: false,
    popularPick: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'CultivatedMind-GardenGrownBCaps',
    displayName: 'EMMETT',
    script: false,
    popularPick: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Pompiere-Regular',
    displayName: 'Alexander',
    script: false,
    popularPick: true,
    size: 3.5,
    favorite: false,
  },
  // end popular picks
  {
    fontFamily: 'Aldedesign-WisdomMerry',
    displayName: 'elizabeth',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Apricots-Regular',
    displayName: 'Charlotte',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Awanstudio-Aubrielle-Regular',
    displayName: 'Aubree',
    script: true,
    size: 4.5,
    favorite: false,
  },
  {
    fontFamily: 'Bralyn',
    displayName: 'Jane',
    script: true,
    size: 4.5,
    favorite: false,
  },
  {
    fontFamily: 'BrittanySignature',
    displayName: 'Brittany',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Datalegreya-Dot',
    displayName: 'WESTON',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Fikryal-HelloSnowflake',
    displayName: 'Jasmine',
    script: true,
    size: 4,
    favorite: false,
  },
  {
    fontFamily: 'Gistesy',
    displayName: 'Gabby',
    script: true,
    size: 4,
    favorite: false,
  },
  {
    fontFamily: 'hellohoney',
    displayName: 'Haley',
    script: true,
    size: 4.5,
    favorite: false,
  },
  {
    fontFamily: 'JeffLevine-InlineRetroJNL',
    displayName: 'OLIVER',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Khurasan-Sheenaz',
    displayName: 'Heather',
    script: true,
    size: 4.5,
    favorite: false,
  },
  {
    fontFamily: 'LoveloBlack',
    displayName: 'BRAXTON',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MegaType-KameliaScript-Regular',
    displayName: 'Danielle',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MegaType-YasmineGardner',
    displayName: 'Rosalie',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Putracetol-CavilayScript',
    displayName: 'Scarlett',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Raleway-Medium',
    displayName: 'Benjamin',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Samtype-HebrewKarina-Regular',
    displayName: 'Jonathan',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SoftMaker-TypewriterSerial',
    displayName: 'hunter',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SophiaRonald',
    displayName: 'Sofia',
    script: true,
    size: 7,
    favorite: false,
  },
  {
    fontFamily: 'Typesketchbook-BetmRounded-ExtraLight',
    displayName: 'Carter',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Alegreya-Regular',
    displayName: 'Nathan',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'BebasNeue-Regular',
    displayName: 'PATRICK',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Fontforecast-SaltAndSpicesMonoBold',
    displayName: 'Emmie',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Hanoded-LemonYellowSunExtraBoldRegular',
    displayName: 'JOSHUA',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Lemonthe-CaltonEleganceScript',
    displayName: 'Callie',
    script: true,
    size: 4.5,
    favorite: false,
  },
  {
    fontFamily: 'LibreBaskerville-Regular',
    displayName: 'Damien',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MalisiaScript',
    displayName: 'Celine',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Blushed',
    displayName: 'Paisley',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Foxtrot',
    displayName: 'JACKSON',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Gingerly',
    displayName: 'CHARLES',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'HoneyButter',
    displayName: 'GRAYSON',
    script: false,
    size: 3.5,
    favorite: false,
  },
  ////////////////////////////////
  // New fonts - block
  {
    fontFamily: 'Backroads',
    displayName: 'Brodie',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'BonVivantSerifBold',
    displayName: 'Bonnie',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'ColdBrew',
    displayName: 'Bev',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Elementary',
    displayName: 'Boston',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'GeorgiaPeach',
    displayName: 'Georgia',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Imaginary',
    displayName: 'Imogen',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MagicDust',
    displayName: 'Maggie',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Roselyn2',
    displayName: 'Robin',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SafeHolidayFamily',
    displayName: 'Poppy',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Sparkle',
    displayName: 'Sparkle',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'WildMango',
    displayName: 'Indie',
    script: false,
    size: 3.5,
    favorite: false,
  },
  ////////////////////////////////
  // New fonts - script
  {
    fontFamily: 'Bluebird',
    displayName: 'Birdie',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'CadosaScript',
    displayName: 'Carmen',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'CaramelEspresso',
    displayName: 'Carrie',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Charmed',
    displayName: 'Camila',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Honeybun',
    displayName: 'Bunny',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Hopeful',
    displayName: 'Joyful',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Julietta',
    displayName: 'Julia',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Sugardrop',
    displayName: 'Sugar',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SummerBerries',
    displayName: 'Summer',
    script: true,
    size: 3.5,
    favorite: false,
  },
  ////////////////////////
  // New scripts
  {
    fontFamily: 'Aisyah-Demo',
    displayName: 'Ally',
    script: true,
    size: 3,
    favorite: false,
  },
  {
    fontFamily: 'GlooryScript-Regular',
    displayName: 'Groovy',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Kimberly',
    displayName: 'Kimber',
    script: true,
    size: 5,
    favorite: false,
  },
  {
    fontFamily: 'Melinda-script',
    displayName: 'Melly',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'NotoSansGurmukhi',
    displayName: 'Nelson',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Saffron',
    displayName: 'Stella',
    script: true,
    size: 3.5,
    favorite: false,
  },////////////////////////////////////////
  {
    fontFamily: 'Adore',
    displayName: 'Adore',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'BlancheSemiBold',
    displayName: 'BLANC',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'CanyonlandsScript',
    displayName: 'Canyon',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'FantastiqueSans',
    displayName: 'Sam',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'FantastiqueScript',
    displayName: 'Holly',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MissMagnolia-Script',
    displayName: 'Missy',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'ModernBohemianSans-Regular',
    displayName: 'HARRY',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'ModernBohemianScript-Regular',
    displayName: 'Boho',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MontereyScript',
    displayName: 'Monterey',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'MontlakeRoad',
    displayName: 'Monty',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Neutraface-Text-Book',
    displayName: 'Nash',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Neutraface-Text-Book-Italic',
    displayName: 'Nash Italic',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Sangria',
    displayName: 'Shandy',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Somersette',
    displayName: 'Somers',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SummerLemonade-SansTwo',
    displayName: 'JACK',
    script: false,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'SummerLemonade-Script',
    displayName: 'Lemons',
    script: true,
    size: 3.5,
    favorite: false,
  },
  {
    fontFamily: 'Montserrat-Medium',
    displayName: 'Sage',
    script: false,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'Montserrat-MediumItalic',
    displayName: 'Sage Italic',
    script: false,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'Vani',
    displayName: 'Vani',
    script: false,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'HarlowDuoScript',
    displayName: 'Harlow',
    script: true,
    size: 3,
    favorite: false,
  },{
    fontFamily: 'HarlowDuoSerif',
    displayName: 'Hardy',
    script: false,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'Hermitage',
    displayName: 'Hazel',
    script: true,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'SoftWhisperingsBold',
    displayName: 'Winnie',
    script: true,
    size: 2.5,
    favorite: false,
  },{
    fontFamily: 'TheSeasons-Reg',
    displayName: 'Sawyer',
    script: false,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'SapphireScript',
    displayName: 'Saphire',
    script: true,
    size: 3.5,
    favorite: false,
  },{
    fontFamily: 'VanillaCupcake',
    displayName: 'Cupcake',
    script: true,
    size: 3.5,
    favorite: false,
  },
];

export const getScriptFonts = fontRegistry
  .filter(({script}) => script);

export const getBlockFonts = fontRegistry
  .filter(({script}) => !script);
